import styles from '../assets/sass/components/index.module.scss';
import '../assets/sass/components/nomodule/program.scss';
import { useCallback, useEffect } from 'react';
import {Link} from 'react-router-dom'
import $ from 'jquery';
import { Tel } from './elements/Tel';
import { NAME, TEL_NUMBER } from '../common/Consts';
import { ReceptionFix } from './blocks/ReceptionFix';

export const Header = () => {

  /** ヘッダーメニュー開閉処理 */
  const menuToggle = useCallback(() => {
    const button = document.querySelector('.js_menuBtn');
    const menu = document.querySelector('.js_menu');
    const buttonClass = button.classList;
    const menuClass = menu.classList;
    buttonClass.toggle('is_active');
    if(buttonClass.contains('is_active')) {
      $('.js_menu').slideDown(450);
    } else {
      $('.js_menu').slideUp(450);
    }
  }, []);

  /** ヘッダーメニュー閉処理 */
  const menuRemove = useCallback(() => {
    const button = document.querySelector('.js_menuBtn');
    const buttonClass = button.classList;
    buttonClass.remove('is_active');
    $('.js_menu').slideUp(350);
  }, []);

  /** 受付時間開閉処理 */
  const receptionToggle = useCallback(() => {
    const reception = document.querySelector('.js_reception');
    const receptionClass = reception.classList;
    receptionClass.toggle('is_active');
  }, []);

  /** 受付時間閉処理 */
  const receptionRemove = useCallback(() => {
    const reception = document.querySelector('.js_reception');
    const receptionClass = reception.classList;
    receptionClass.remove('is_active');
  }, []);

   /** リサイズ時削除 */
  const menuResize = useCallback(() => {
    if(window.innerWidth >= 768) {
      menuRemove();
      receptionRemove();
    }
  }, []);

  useEffect(() => {
    /** 各種イベントトリガー取得 */
    const menuButton = document.querySelector('.js_menuBtn');
    const menuButtonRemoves = document.querySelectorAll('.js_close_menu');
    const receptionButton = document.querySelector('.js_open_reception');
    const receptionButtonRemove = document.querySelector('.js_close_reception');
  
    /** 各種イベント登録 */
    menuButton.addEventListener('click', menuToggle);
    menuButtonRemoves.forEach(menuButtonRemove => {
      menuButtonRemove.addEventListener('click', menuRemove);
    });
    receptionButton.addEventListener('click', receptionToggle);
    receptionButtonRemove.addEventListener('click', receptionRemove);
    window.addEventListener('resize', menuResize);
  }, [])

  return(
    <>
    <header className={styles.hd_header}>
      <div className={styles.hd_header_inner}>
        <div className={styles.hd_header_left}>
          <Link to="/" className={styles.hd_header_left_logoWrap}><img src={`${process.env.PUBLIC_URL}/img/logo.svg`}alt="" /><span>アール・アイ整体</span></Link>
          <div className={styles.hd_header_tel}>
            <Tel />
          </div>
        </div>
        <div className={`${styles.hd_header_right} ${styles.hd_header_right__pc}`}>
          <nav className={styles.hd_header_nav}>
            <Link to='/about'><span className={styles.hd_header_link}>イネイト活性療法とは</span></Link>
            <Link to='/news'><span className={styles.hd_header_link}>お知らせ</span></Link>
            <Link to='/contact'><span className={styles.hd_header_link}>お問い合わせ</span></Link>
          </nav>
        </div>
        <div className={`${styles.hd_header_right} ${styles.hd_header_right__sp}`}>
          <div className={styles.hd_header_actionWrap}>
            <div className={`${styles.hd_header_action} js_open_reception`}>
              <img src={`${process.env.PUBLIC_URL}/img/icon_clock_w.svg`} alt="" />
              <span className={styles.hd_header_action_txt}>受付時間</span>
            </div>
            <a href={`tel:${TEL_NUMBER}`}>
              <div className={`${styles.hd_header_action} ${styles.hd_header_action__b}`}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon_tel.svg`} alt="" />
                  <span className={styles.hd_header_action_txt}>Tel.</span>
              </div>
            </a>
          </div>
          <div className={`${styles.hd_header_menuBtn} js_menuBtn`}>
            <span className={`${styles.hd_header_menuBtn_line} ${styles.hd_header_menuBtn_line__top}`}></span>
            <span className={`${styles.hd_header_menuBtn_line} ${styles.hd_header_menuBtn_line__center}`}></span>
            <span className={`${styles.hd_header_menuBtn_line} ${styles.hd_header_menuBtn_line__bottom}`}></span>
          </div>
        </div>
        <div className={`${styles.hd_header_menu} js_menu`}>
          <div className={styles.hd_header_menu_inner}>
            <nav className={styles.hd_header_nav}>
              <Link to='/'><span className={`${styles.hd_header_link} js_close_menu`}>トップ</span></Link>
              <Link to='/about'><span className={`${styles.hd_header_link} js_close_menu`}>イネイト活性療法とは</span></Link>
              <Link to='/news'><span className={`${styles.hd_header_link} js_close_menu`}>お知らせ</span></Link>
              <Link to='/contact'><span className={`${styles.hd_header_link} js_close_menu`}>お問い合わせ</span></Link>
            </nav>
            <Tel />
          </div>
        </div>
      </div>
    </header>
    <ReceptionFix></ReceptionFix>
    </>
  ) 
};
