import styles from '../../assets/sass/components/index.module.scss';
import { RECEPTION_TIME, REGULAR_HOLIDAY } from '../../common/Consts';
import { Button } from '../elements/Button';
import { Tel } from '../elements/Tel';

/**
 * @param strng link ボタンのとび先 
 * @param string anchor 他画面へのアンカーリンク　該当のid
 * @param string smooth 同一画面内のスムーススクロール　該当のid
 * @param string children テキスト
 */
export const ReservationButton = (props) => {
  const {button=null, link=null, anchor=null, smooth=null, children} = props;

  return (
    <div className={styles.bl_reservation}>
      <h4 className={styles.bl_reservation_head}>ご予約はこちらから</h4>
      <p className={styles.bl_reservation_ex}>
        {`電話受付時間：${RECEPTION_TIME}　${REGULAR_HOLIDAY}`}
      </p>
      <Tel />
      {}
      {
        anchor? 
        <Button type="a" link={link} anchor={anchor}>{children}</Button>:
        <Button type="a" link={link} smooth={smooth}>{children}</Button>
      }
    </div>
  )
}
