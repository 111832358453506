import pages from '../assets/sass/pages/various.module.scss';
import helpers from '../assets/sass/components/helper.module.scss';
import { TEL_NUMBER, VARIOUS_ID_CONTACT_COMPLETE, VARIOUS_ID_CONTACT_ERROR} from '../common/Consts';
import { Helmet } from 'react-helmet-async';
import { Container } from '../components/layouts/Container';
import { Button } from '../components/elements/Button';
import { Head } from "../components/elements/Head";

export const Various = (props) => {
  const {type} = props;
  let head = null;
  if(type === VARIOUS_ID_CONTACT_COMPLETE) {
    head = 'お問い合わせ送信完了';
  } else if(type === VARIOUS_ID_CONTACT_ERROR) {
    head = 'お問い合わせ送信エラー'
  } else {
    head = '404 Not Found'
  }
  return (
    <>
      <Helmet>
        <title>アール・アイ整体 | {head}</title>
      </Helmet>
      <Head>{head}</Head>
      <Container>
        {
          type === VARIOUS_ID_CONTACT_COMPLETE? (
            <>
              <p className={pages.various_title}>
                お問い合わせいただき<br className={helpers.hp_none__pc} />誠にありがとうございます。
              </p>
              <p className={pages.various_ex}>
              この度はお問い合わせいただき誠にありがとうございます。ご入力いただいた内容を確認させていただき、担当者よりご連絡いたします。<br />また、お問い合わせの受付完了の確認のため、自動配信メールを送信しております。しばらく経ってもメールが届かない場合、ご入力いただいたメールアドレスに誤りがあるか、迷惑メールフォルダに振り分けられている可能性がございます。大変お手数お掛け致しますが、メールが届かない場合は上記の内容をご確認いただき、再度お問い合わせフォームにてご連絡いただきますようお願い申し上げます。<br />また、お電話でもお問合せを受け付けておりますので、急用の方はお電話よりご連絡くださいませ。 <br />({TEL_NUMBER})
              </p>
            </>
          ) :

          type === VARIOUS_ID_CONTACT_ERROR? (
            <>
              <p className={pages.various_title}>
                お問い合わせの送信に<br className={helpers.hp_none__pc} />失敗しました。
              </p>
              <p className={pages.various_ex}>
              お問い合わせが正常に行われませんでした。恐れ入りますが、しばらく経ってから再度お問い合わせください。<br />またお急ぎの場合は、お手数をお掛けいたしますがお電話にてご連絡をお願いいたします。<br className={helpers.hp_none__pc} />({TEL_NUMBER})
              </p>
            </>
          ) :

          (
            <>
              <p className={pages.various_title}>
                ページがみつかりませんでした。
              </p>
              <p className={pages.various_ex}>
                お探しのページがみつかりませんでした。恐れ入りますが、トップページよりアクセスしてください。
              </p>
            </>
          )
        }
        <div className={pages.various_button}>
          <Button type="a" link="/">トップへ戻る</Button>
        </div>
      </Container>
    </>
  )
};
