import styles from '../../assets/sass/components/index.module.scss';
import { Reception } from './Reception';

export const ReceptionFix = () => {
  return (
    <div className={`${styles.bl_receptionFix} js_reception`}>
      <div className={styles.bl_receptionFix_reception}>
        <Reception />
      </div>
      <div className={`${styles.bl_receptionFix_close} js_close_reception`}>
        <img className={`${styles.bl_receptionFix_close_icon} {'js_close_reception'}`} src={`${process.env.PUBLIC_URL}/img/icon_close.svg`} alt="" />
      </div>
      <div className={styles.bl_receptionFix_inner}>
        <img className={styles.bl_receptionFix_icon} src={`${process.env.PUBLIC_URL}/img/icon_clock.svg`} alt="" />
        <span className={styles.bl_receptionFix_txt}>受付時間</span>
      </div>
    </div>
  )
}
