import styles from '../../assets/sass/components/index.module.scss';
import {Link} from 'react-router-dom'

export const TextLink = (props) => {
  const {link, children} = props;
  return (
    <Link to={link}>
      <div className={styles.el_link}>
        <span className={styles.el_link_txt}>{children}</span>
        <img className={styles.el_link_icon} src={`${process.env.PUBLIC_URL}/img/icon_arow.svg`} alt="" />
      </div>
    </Link>
  )
}
