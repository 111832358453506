/** メディアクエリPCの開始サイズ */
export const PC_WIDTH = 768;

/** various画面の判別 お問い合わせ完了 */
export const VARIOUS_ID_CONTACT_COMPLETE = 'complete';

/** various画面の判別 お問い合わせ送信エラー */
export const VARIOUS_ID_CONTACT_ERROR = 'contact-err';

/** 受付時間 */
export const RECEPTION_TIME = '09:00～18:00';

/** 定休日 */
export const REGULAR_HOLIDAY = '不定休';

/** 電話番号 */
export const TEL_NUMBER = '080-3437-2212';

/** 院名 */
export const NAME = 'アール・アイ整体';

/** 郵便番号 */
export const POST_CODE = '〒370-0422';

/** 住所 */
export const ADDRESS = '群馬県太田市安養寺町56-6';
