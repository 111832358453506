import pages from '../assets/sass/pages/policy.module.scss';
import helpers from '../assets/sass/components/helper.module.scss';
import { Helmet } from 'react-helmet-async';
import { NAME } from '../common/Consts';
import { ContainerMin } from '../components/layouts/ContainerMin';
import { Head } from "../components/elements/Head";

export const Policy = () => {
  return (
    <>
      <Helmet>
        <title>アール・アイ整体 | プライバシーポリシー</title>
      </Helmet>
      <Head>プライバシーポリシー</Head>
      <ContainerMin max="900">
        <p>
          {NAME}(以下、「当院」といいます。)は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
        </p>
        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第1条<span className={pages.policy_head_blank}></span>個人情報
          </h2>
          <p>
            「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
          </p>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第2条<span className={pages.policy_head_blank}></span>個人情報の収集方法
          </h2>
          <p>
            当院は、お問い合わせの際に、氏名、電話番号、メールアドレス等の個人情報を取得させていただきます。
          </p>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第3条<span className={pages.policy_head_blank}></span>個人情報を収集・利用する目的
          </h2>
          <p>
            当院が個人情報を収集・利用する目的は、以下のとおりです。
          </p>
          <div className={`${pages.policy_numberWrap} ${pages.policy_bottom}`}>
            <p className={pages.policy_number}>
              本サービスの提供・運営のため
            </p>
            <p className={pages.policy_number}>
              ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
            </p>
            <p className={pages.policy_number}>
              ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当院が提供する他のサービスの案内のメールを送付するため
            </p>
            <p className={pages.policy_number}>
              メンテナンス、重要なお知らせなど必要に応じたご連絡のため
            </p>
            <p className={pages.policy_number}>
              有料サービスにおいて、ユーザーに利用料金を請求するため
            </p>
            <p className={pages.policy_number}>
              上記の利用目的に付随する目的
            </p>
          </div>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第4条<span className={pages.policy_head_blank}></span>利用目的の変更
          </h2>
          <div className={pages.policy_numberWrap}>
            <p className={pages.policy_number}>
              当院は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
            </p>
            <p className={pages.policy_number}>
              利用目的の変更を行った場合には、変更後の目的について、当院の所定の方法により、ユーザーに通知し、または本ウェブサイト上に公表するものとします。
            </p>
          </div>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第5条<span className={pages.policy_head_blank}></span>個人情報の第三者提供
          </h2>
          <p>
            当院は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。
          </p>
          <div className={`${pages.policy_numberWrap} ${pages.policy_bottom}`}>
            <p className={pages.policy_number}>
              当院は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
            </p>
            <p className={pages.policy_number}>
              利用目的の変更を行った場合には、変更後の目的について、当院の所定の方法により、ユーザーに通知し、または本ウェブサイト上に公表するものとします。
            </p>
          </div>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第6条<span className={pages.policy_head_blank}></span>個人情報の開示
          </h2>
          <div className={pages.policy_numberWrap}>
            <p className={pages.policy_number}>
              当院は、本人から個人情報の開示を求められたときは、本人であることを確認したうえで、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
            </p>
            <div className={`${pages.policy_numberWrap} ${pages.policy_bottom} ${pages.policy_in}`}>
              <p className={pages.policy_number}>
                本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
              </p>
              <p className={pages.policy_number}>
                当院の業務の適正な実施に著しい支障を及ぼすおそれがある場合
              </p>
              <p className={pages.policy_number}>
                その他法令に違反することとなる場合
              </p>
            </div>
            <p className={`${pages.policy_number} ${pages.policy_paragraph}`}>
              前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
            </p>
          </div>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第7条<span className={pages.policy_head_blank}></span>個人情報の訂正および<br className={helpers.hp_none__pc} />利用の停止等
          </h2>
          <div className={pages.policy_numberWrap}>
            <p className={pages.policy_number}>
              当院は、ユーザーから、個人情報の内容が事実ではなく、訂正・追加・削除（以下「訂正等」という）を求められたときは、ユーザーご本認からのご請求であることを確認のうえ遅滞なく調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をユーザーに通知します。
            </p>
            <p className={pages.policy_number}>
              当院は、ユーザーから、以下の各号の理由に基づき個人情報の利用の停止または消去（以下「利用の停止等」という）を求められたときは、ユーザーご本認からのご請求であることを確認のうえ遅滞なく調査を行い、その結果に基づき、必要に応じて個人情報の利用の停止等を行い、その旨をユーザーに通知します。
            </p>
            <div className={`${pages.policy_numberWrap} ${pages.policy_bottom} ${pages.policy_in}`}>
              <p className={pages.policy_number}>
                あらかじめ公表された利用目的の範囲を超えて個人情報が取扱われているという理由
              </p>
              <p className={pages.policy_number}>
                偽りその他の手段により個人情報が取得されたものであるという理由
              </p>
            </div>
            <p className={`${pages.policy_number} ${pages.policy_paragraph}`}>
              個人情報保護法その他の法令により、当院が訂正等または利用の停止等の義務を負わない場合、本条１項、２項の定めは適用されません。
            </p>
          </div>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第8条<span className={pages.policy_head_blank}></span>cookieについて
          </h2>
          <p>
            Cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。WebサーバーがCookieを参照することでユーザーのパソコンを識別でき、効率的に当院Webサイトを利用することができます。当院のWebサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。お使いのWebブラウザの設定により、cookieを無効にすることも可能です。
          </p>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第9条<span className={pages.policy_head_blank}></span>Googleアナリティクスについて
          </h2>
          <p>
            本ウェブサイトではGoogleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ます。本条に関しての詳細はGoogleアナリティクスサービス利用規約のページやGoogleポリシーと規約ページをご覧ください。
          </p>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第10条<span className={pages.policy_head_blank}></span>SSL(Secure Socket Layer)に<br className={helpers.hp_none__pc} />ついて
          </h2>
          <p>
            当院のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
          </p>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第11条<span className={pages.policy_head_blank}></span>プライバシーポリシーの変更
          </h2>
          <p className={pages.policy_number}>
            本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
          </p>
          <p className={pages.policy_number}>
            当院が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
          </p>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第12条<span className={pages.policy_head_blank}></span>免責事項
          </h2>
          <p className={pages.policy_number}>
            当院Webサイトに掲載されている情報の正確さには万全を期していますが、利用者が当院Webサイトの情報を用いて行う一切の行為に関して、当院は一切の責任を負わないものとします。
          </p>
          <p className={pages.policy_number}>
            当院は、利用者が当院Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
          </p>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第13条<span className={pages.policy_head_blank}></span>著作権・肖像権
          </h2>
          <p>
            当院Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されており、無断での使用や転用は禁止されています。
          </p>
        </div>

        <div className={pages.policy_wrap}>
          <h2 className={pages.policy_head}>
            第14条<span className={pages.policy_head_blank}></span>リンク
          </h2>
          <p>
            当院Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンク設置をお断りすることがあります。
          </p>
        </div>
      </ContainerMin>
    </>
  )
};
