/** 
 * 特定位置へのスムーススクロール
 * @param target 目的地のid
 * @returns
 */
export const smoothLink = (target) => {
  const targetElement = document.getElementById(target);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
}
