import pages from '../assets/sass/pages/top.module.scss';
import helpers from '../assets/sass/components/helper.module.scss';
import '../assets/sass/components/nomodule/plugin.scss';
import React, { useEffect }  from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { NAME } from '../common/Consts';
import { Section } from '../components/layouts/Section';
import { ColorBg } from '../components/layouts/ColorBg';
import { Container } from '../components/layouts/Container';
import { Article } from '../components/blocks/Article';
import { Reservation } from '../components/blocks/Rervation';
import { BgReact } from '../components/blocks/BgReact';
import { Circle } from '../components/elements/Circle';
import { Square } from '../components/elements/Square';
import { TextLink } from '../components/elements/TextLink';
import { Title } from '../components/elements/Title';
import { MarkLg } from '../components/elements/MarkLg';
import { MarkSm } from '../components/elements/MarkSm';
import { Button } from '../components/elements/Button';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.min.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export const Top = () => {

  const settings = {
    arrows: false,
    accessibility: false,
    dots: false,
    infinite: true,
    infinite: true,
    slidesToShow: 1.5,
    centerMode: false,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 0,
    pauseOnFocus: false,
    pauseOnHover: false,
    cssEase: "linear",
    pauseOnFocus: false,
    pauseOnHover: false,
  };

  const settingsPc = {
    arrows: false,
    accessibility: false,
    dots: false,
    infinite: true,
    infinite: true,
    slidesToShow: 4.2,
    centerMode: false,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 0,
    pauseOnFocus: false,
    pauseOnHover: false,
    cssEase: "linear",
    pauseOnFocus: false,
    pauseOnHover: false,
  };

  const images = [
    `${process.env.PUBLIC_URL}/img/img_slide01.webp`,
    `${process.env.PUBLIC_URL}/img/img_slide02.webp`,
    `${process.env.PUBLIC_URL}/img/img_slide03.webp`,
    `${process.env.PUBLIC_URL}/img/img_slide04.webp`
  ]

  const problems = [
    [
      `${process.env.PUBLIC_URL}/img/img_top_problem01.webp`,
      'いつも体がけだるくやる気がでない'
    ],
    [
      `${process.env.PUBLIC_URL}/img/img_top_problem02.webp`,
      '生理痛・排卵痛がひどくつらい'
    ],
    [
      `${process.env.PUBLIC_URL}/img/img_top_problem03.webp`,
      '肩こり、首こりがひどい'
    ],
    [
      `${process.env.PUBLIC_URL}/img/img_top_problem04.webp`,
      '様々なセルフケア・施術でも<br>全くトラブルが改善されない'
    ],
    [
      `${process.env.PUBLIC_URL}/img/img_top_problem05.webp`,
      `姿勢の悪さが気になっている`
    ],
    [
      `${process.env.PUBLIC_URL}/img/img_top_problem06.webp`,
      '頭痛やめまいによって<br>気持ちがわるくなってしまう'
    ],
    [
      `${process.env.PUBLIC_URL}/img/img_top_problem07.webp`,
      '背中の痛みや腰痛がひどく<br>朝起きるのがつらい'
    ],
  ]

  const questions = [
    [
      '施術は痛くないですか？', 
      'はい、まったく痛くありません。<br>当院の施術は、押したり揉んだり引っ張ったり、痛くて動かせない部分を無理矢理に動かしたりはしません。極めて体に負担の少ない整体で、気持ちよくてお客様が眠ってしまうこともあります。'
    ],
    [
      '施術時間はどれくらいですか？', 
      '初見の方は、質問がありますので４０分を目安とお考えください。２回目以降の方は、２０〜３０分を目安とお考えください。'
    ],
    [
      '整体頻度や回数はどうすればよいでしょうか？', 
      '基本的にはこちらから最善の頻度や回数をご提案させていただきます。'
    ],
    [
      '服装はどうすればよいですか？', 
      '動きやすい服装をご用意願います。お着替えのスペースもございます。'
    ],
  ]
  
  return (
    <>
      <Helmet>
        <title>アール・アイ整体 | 群馬県太田市 イネイト活性療法療法</title>
      </Helmet>
      {/* TODO メインビジュアル}
      <div className={pages.top_main}>
        <div className={pages.top_main_inner}>
          <h1 className={pages.top_main_head}>
            証明された正しい検査で<br className={helpers.hp_none__pc}/>
            根本改善を実現。
          </h1>
          <div className={pages.top_main_news}>
            <span className={pages.top_main_newsLabel}>お知らせ</span>
            <div className={pages.top_main_newsArticle}>
              <Link to="/news#">
                <Article row='on' date={'2023.09.06'}>
                  タイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトル
                </Article>
              </Link>
              <Link to="/news#">
                <Article row='on' date={'2023.09.06'}>
                  タイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトル
                </Article>
              </Link>
              <Link to="/news#">
                <Article row='on' date={'2023.09.06'}>
                  タイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトル
                </Article>
              </Link>
            </div>
            <div className={pages.top_main_button}>
              <Button type="a" link="/news">もっと見る</Button>
            </div>
          </div>
        </div>
        <div className={pages.top_main_circle}>
          <Circle />
        </div>
        <div className={pages.top_main_square}>
          <Square />
        </div>
        <div className={`${pages.top_main_circle} ${pages.top_main_circle__pc}`}>
          <Circle />
        </div>
        <div className={`${pages.top_main_square} ${pages.top_main_square__pc}`}>
          <Square />
        </div>
      </div>
      TODO メインビジュアル - END */}
      <Section>
        <Title>こんなお悩みは<br className={helpers.hp_none__pc} />ありませんか?</Title>
        <Slider className={`${pages.top_slide_inner} ${pages.top_slide_inner__sp} `} {...settings}>
          {problems.map((problem, index) => (
            <div className={pages.top_slide_item} key={index}> 
              <img className={pages.top_slide_itemImg} src={problem[0]} alt="" key={problem[1]} />
              {problem[1].split('<br>').map((txt, index) => (
                <p className={pages.top_slide_itemTxt} key={index}>{txt}</p>
              ))}
            </div>
          ))}
        </Slider>
        <Slider className={`${pages.top_slide_inner} ${pages.top_slide_inner__pc} `} {...settingsPc}>
          {problems.map((problem, index) => (
            <div className={pages.top_slide_item} key={index}> 
              <img className={pages.top_slide_itemImg} src={problem[0]} alt="" />
              {problem[1].split('<br>').map((txt, index) => (
                <p className={pages.top_slide_itemTxt} key={index}>{txt}</p>
              ))}
            </div>
          ))}
        </Slider>
      </Section>
      <Section>
        <Container>
          <h2 className={pages.top_concept}>
            科学的効果が証明された<br className={helpers.hp_none__pc}/>イネイト活性療法
          </h2>
          <p className={pages.top_conceptTxt}>
            あなたは科学的に効果が証明された治療を受けていますか？
            エビデンスをとれる治療法は数える程しかありません。エビデンスの無い治療の場合、その効果を証明できず、実際になかなか改善しないという経験をお聞きすることもあります。治療を受けていてもなかなか治らない場合、まずはその治療がエビデンスにより効果を証明されたものかどうかを知ることが大切です。
          </p>
          <p className={pages.top_conceptTxt}>
            当院が取り扱うイネイト活性療法は、エビデンスによって効果が証明された、根本から不調を改善できる治療です。
          </p>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>{NAME}の特徴</Title>
          <div className={pages.top_pointWrap}>
            <div className={pages.top_point}>
              <span className={pages.top_point_number}>1</span>
              <span className={pages.top_point_back}></span>
              <h3 className={pages.top_point_head}>
                エビデンスのある治療
              </h3>
              <img className={pages.top_point_img} src={`${process.env.PUBLIC_URL}/img/img_evidence.png`} alt="" />
              <p className={pages.top_point_txt}>
                当院ではエビデンスによって効果が証明された、イネイト活性療法を取り扱っております。臨床結果に基づいた、正しい治療を行うことができます。
              </p>
              <div className={pages.top_point_link}>
                <TextLink link="/about">イネイト活性療法とは</TextLink>
              </div>
            </div>
            <div className={pages.top_point}>
              <span className={pages.top_point_number}>2</span>
              <span className={pages.top_point_back}></span>
              <h3 className={pages.top_point_head}>
                原因を知ることができる
              </h3>
              <img className={pages.top_point_img} src={`${process.env.PUBLIC_URL}/img/img_feature02.webp`} alt="" />
              <p className={pages.top_point_txt}>
                一度治療した不調も、原因が分からない場合、再発する可能性は高いといえます。当院では原因の特定が可能であり、再発防止につながります。
              </p>
            </div>
            <div className={pages.top_point}>
              <span className={pages.top_point_number}>3</span>
              <span className={pages.top_point_back}></span>
              <h3 className={pages.top_point_head}>
                根本改善が可能
              </h3>
              <img className={pages.top_point_img} src={`${process.env.PUBLIC_URL}/img/img_feature03.webp`} alt="" />
              <p className={pages.top_point_txt}>
                根本の原因を明確化することで、<br className={helpers.hp_none__pc}/>一時の変化ではなく、根本から改善することが可能です。
              </p>
            </div>
            <div className={pages.top_point}>
              <span className={pages.top_point_number}>4</span>
              <span className={pages.top_point_back}></span>
              <h3 className={pages.top_point_head}>
                無痛で施術が可能
              </h3>
              <img className={pages.top_point_img} src={`${process.env.PUBLIC_URL}/img/img_feature04.webp`} alt="" />
              <p className={pages.top_point_txt}>
                イネイト活性療法は安全性が高く、さらに無痛で受けることが可能です。
              </p>
            </div>
          </div>
        </Container>
      </Section>
      {/*
      <Section>
        <Container>
          <div id="fee" className={pages.top_corseWrap}>
            <Title>施術内容・料金</Title>
            <div className={pages.top_corseList}>
              {corses.map((corse, index) => (
                  <div className={pages.top_corse} key={index}>
                    <p className={pages.top_corse_head}>{corse[0]}</p>
                    <p className={pages.top_corse_fee}>{corse[1]}</p>
                  </div>
              ))}
            </div>
            <div className={pages.top_corseWrap_circle}>
              <Circle />
            </div>
            <div className={pages.top_corseWrap_square}>
              <Square />
            </div>
          </div>
        </Container>
      </Section>
      */}
      <Section>
        <Container>
          <div id="fee" className={pages.top_corseWrap}>
            <Title>施術内容・料金</Title>
          </div>
          <div className={pages.top_courseBgReact}>
            <BgReact>
              <h3 className={pages.top_courseWrap_campaignTitle}>初回限定キャンペーン !</h3>
              <p className={pages.top_courseWrap_campaignEx}>
                ご予約時に「ホームページを見た」とお伝えいただきますと<br className={helpers.hp_none__sp} />通常の半額で施術をさせていただきます。
              </p>
              <div className={pages.top_courseWrap_campaignFee}>
                <img className={`${pages.top_courseWrap_icon} ${helpers.hp_none__sp}`}src={`${process.env.PUBLIC_URL}/img/icon_arrow_theme_l.svg`} alt="" />
                <img className={`${pages.top_courseWrap_icon} ${helpers.hp_none__pc}`} src={`${process.env.PUBLIC_URL}/img/icon_arrow_theme_s.svg`} alt="" />
                <div className={pages.top_courseWrap_campaignNormal}>
                  <span className={pages.top_courseWrap_campaignInfo}>通常初回料金</span>
                  <span className={pages.top_courseWrap_campaignNormalFee}>8,000円</span>
                  <span className={pages.top_courseWrap_campaignNormalEx}>(初見料2,000円 + 施術料6,000円)</span>
                </div>
                <div className={pages.top_courseWrap_campaignTo}>
                  <span className={pages.top_courseWrap_campaignInfo}>キャンペーン料金</span>
                  <span className={pages.top_courseWrap_campaignToFee}>4,000円</span>
                </div>
              </div>
            </BgReact>
            <div className={pages.top_courseWrap_exWrap}>
              <h3 className={pages.top_courseWrap_title}>通常料金</h3>
                <span className={pages.top_courseWrap_ex}>初回&nbsp;&nbsp;8,000円</span>
                <span className={pages.top_courseWrap_ex}>初見料2,000円 + 施術料6,000円</span>
                <span className={`${pages.top_courseWrap_ex} ${pages.top_courseWrap_ex02}`}>2回目以降&nbsp;&nbsp;6,000円</span>
                <span className={pages.top_courseWrap_ex}>施術料6,000円のみ</span>
              </div>
              <div className={pages.top_courseWrap_exWrap}>
                <h3 className={pages.top_courseWrap_title}>クーポン券</h3>
                <span className={pages.top_courseWrap_ex}>料金&nbsp;&nbsp;30,000円</span>
                <span className={pages.top_courseWrap_ex}>36,000円分の施術が可能(2回目以降の施術 * 6回)</span>
              </div>  
              <p className={pages.top_courseWrap_end}>
                詳しい内容については、お電話またはお問い合わせフォームよりご連絡をお願いいたします。
              </p>
              <div className={pages.top_courseWrap_button}>
                <Button type="a" link="./contact">お問い合わせへ</Button>
              </div>
          </div>
        </Container>
      </Section>
      <Section>
        <ColorBg>
          <Reservation />
        </ColorBg>
      </Section>
      <Section>
        <Container>
          <div className={pages.top_flowWrap}>
            <Title>施術の流れ</Title>
            <div className={pages.top_flowWrap_circle}>
              <Circle />
            </div>
            <div className={pages.top_flowList}>
              <div className={pages.top_flow}>
                <MarkLg>1</MarkLg>
                <p className={pages.top_flow_txt}>
                  受付を行います。問診表をお渡ししますのでご記入をお願いいたします。
                </p>
              </div>
              <img className={`${helpers.hp_none__sp} ${pages.top_flow_imgPc}`} src={`${process.env.PUBLIC_URL}/img/img_flow_pc.svg`} alt="" /> 
              <div className={`${pages.top_flow} ${pages.top_flow__re}`}>
                <div className={pages.top_flow_numWrap}>
                  <img className={helpers.hp_none__pc} src={`${process.env.PUBLIC_URL}/img/img_flow_sp.svg`} alt="" /> 
                  <MarkLg>2</MarkLg>
                </div>
                <p className={pages.top_flow_txt}>
                  問診を行います。些細な不安もすべてお聞かせください。
                </p>
              </div>
              <img className={`${helpers.hp_none__sp} ${pages.top_flow_imgPc}`} src={`${process.env.PUBLIC_URL}/img/img_flow_pc.svg`} alt="" /> 
              <div className={`${pages.top_flow} ${pages.top_flow}`}>
                <div className={pages.top_flow_numWrap}>
                  <MarkLg>3</MarkLg>
                  <img className={helpers.hp_none__pc} src={`${process.env.PUBLIC_URL}/img/img_flow_sp_re.svg`} alt="" /> 
                </div>
                <p className={pages.top_flow_txt}>
                  身体の「機能」を測る検査を行います。<br />骨格・筋肉、姿勢の状態等を検査します。
                </p>
              </div>
              <img className={`${helpers.hp_none__sp} ${pages.top_flow_imgPc}`} src={`${process.env.PUBLIC_URL}/img/img_flow_pc.svg`} alt="" /> 
              <div className={`${pages.top_flow} ${pages.top_flow__re}`}>
                <div className={pages.top_flow_numWrap}>
                  <img className={helpers.hp_none__pc} src={`${process.env.PUBLIC_URL}/img/img_flow_sp.svg`} alt="" /> 
                  <MarkLg>4</MarkLg>
                </div>
                <p className={pages.top_flow_txt}>
                  身体の「反射」を測る検査を行います。脳からの神経伝達機能の強さを調べます。
                </p>
                <img className={`${helpers.hp_none__sp} ${pages.top_flow_imgPcRe}`} src={`${process.env.PUBLIC_URL}/img/img_flow_pc_re.svg`} alt="" /> 
              </div>
              <div className={`${pages.top_flow} ${pages.top_flow}`}>
                <div className={pages.top_flow_numWrap}>
                  <MarkLg>5</MarkLg>
                  <img className={helpers.hp_none__pc} src={`${process.env.PUBLIC_URL}/img/img_flow_sp_re.svg`} alt="" /> 
                </div>
                <p className={pages.top_flow_txt}>
                  先の2つの検査から、根本原因を追及する検査をします。最も重要な行程です。
                </p>
              </div>
              <img className={`${helpers.hp_none__sp} ${pages.top_flow_imgPc}`} src={`${process.env.PUBLIC_URL}/img/img_flow_pc.svg`} alt="" /> 
              <div className={`${pages.top_flow} ${pages.top_flow__re}`}>
                <div className={pages.top_flow_numWrap}>
                  <img className={helpers.hp_none__pc} src={`${process.env.PUBLIC_URL}/img/img_flow_sp.svg`} alt="" /> 
                  <MarkLg>6</MarkLg>
                </div>
                <p className={pages.top_flow_txt}>
                  症状の原因と、メカニズムの説明を行います。どのようにすれば改善できるのかをご説明いたします。
                </p>
              </div>
              <img className={`${helpers.hp_none__sp} ${pages.top_flow_imgPc}`} src={`${process.env.PUBLIC_URL}/img/img_flow_pc.svg`} alt="" /> 
              <div className={`${pages.top_flow} ${pages.top_flow}`}>
                <div className={pages.top_flow_numWrap}>
                  <MarkLg>7</MarkLg>
                  <img className={helpers.hp_none__pc} src={`${process.env.PUBLIC_URL}/img/img_flow_sp_re.svg`} alt="" /> 
                </div>
                <p className={pages.top_flow_txt}>
                  イネイト活性療法による治療を行います。無痛による治療ですので、安心してお受けいただけます。
                </p>
              </div>
              <img className={`${helpers.hp_none__sp} ${pages.top_flow_imgPc}`} src={`${process.env.PUBLIC_URL}/img/img_flow_pc.svg`} alt="" /> 
              <div className={`${pages.top_flow} ${pages.top_flow__re}`}>
                <div className={pages.top_flow_numWrap}>
                  <img className={helpers.hp_none__pc} src={`${process.env.PUBLIC_URL}/img/img_flow_sp.svg`} alt="" /> 
                  <MarkLg>8</MarkLg>
                </div>
                <p className={pages.top_flow_txt}>
                  治療後の説明、日常生活のアドバイスを行います。善い状態を維持するためにできることをお伝えいたします。
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section>
        <Container>
          <div className={pages.top_certificate}>
            <h3 className={pages.top_certificate_title}>
              当院にはイネイト活性療法の<br className={helpers.hp_none__pc} />技術の証明を取得した整体師<br className={helpers.hp_none__sp} />が所属しており、安心・安全に<br className={helpers.hp_none__pc} />治療が受けられます。
            </h3>
            <div className={pages.top_certificate_imgWrap}>
              <img src={`${process.env.PUBLIC_URL}/img/img_certificate.jpg`} alt="" />
              <img src={`${process.env.PUBLIC_URL}/img/img_certificate02.jpg`} alt="" />
            </div>
          </div>
        </Container>
      </Section>
      <Section>
        <Title>院長紹介</Title>
        <div className={pages.top_personWrap}>
          <div className={pages.top_person}>
            <img src={`${process.env.PUBLIC_URL}/img/person_chiropractor.webp`} 
            alt="" />
          </div>
          <img className={`${pages.top_person_back} ${helpers.hp_none__pc}`} src={`${process.env.PUBLIC_URL}/img/img_person_bg.svg`} alt="" />
          <img className={`${pages.top_person_back} ${helpers.hp_none__sp}`} src={`${process.env.PUBLIC_URL}/img/img_person_bg_pc.svg`} alt="" />
          <p className={pages.top_person_txt}>
            私自身、8年以上腰痛に悩んでおりました。様々なケアを行い改善を試みるも、全く改善されず苦しんでいたところ、イネイト活性療法に出会いました。これまで全くよくならなかった不調が改善したのです。<br /><br />
            私自身が身をもって体験したこのイネイト活性療法の素晴らしさを、より多くの人に体験していただき、一人でも多くの方のお悩みを解決したいと思い開業いたしました。<br />なかなか治らない体の不調に悩んでいる方は、是非一度御相談を！！<br />
            <span className={pages.top_person_name}>橋本 伸二</span>
          </p>
        </div>
      </Section>
      <Section>
        <Container>
          <Title>よくあるご質問</Title>
          {questions.map((question, index) => (
            <div className={pages.top_qa} key={index}>
              <div className={pages.top_qa_headWrap}>
                <div>
                  <MarkSm>Q</MarkSm>
                </div>
                <p className={pages.top_qa_head}>
                  {question[0]}
                </p>
              </div>
              <div className={pages.top_qa_ex}>
                {question[1].split('<br>').map((txt, index) => (
                <p key={index}>{txt}</p>
              ))}
              </div>
          </div>
          ))}
        </Container>
      </Section>
    </>
  )
};
