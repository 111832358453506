import './assets/sass/common/reset.scss';
import './assets/sass/common/common.scss';
import styles from './assets/sass/components/index.module.scss';
import { Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Top } from './pages/Top';
import { About } from './pages/About';
import { ArchiveNews } from './pages/ArchiveNews';
import { Contact } from './pages/Contact';
import { Policy } from './pages/Policy';
import { Various } from './pages/Various';
// import { Test } from './Test';
import { Components } from './Components';
import { Pre } from './Pre';
import { ScrollToTop } from './common/ScrollToTop';
import $ from 'jquery';
import { VARIOUS_ID_CONTACT_COMPLETE, VARIOUS_ID_CONTACT_ERROR, VARIOUS_ID_ERROR } from './common/Consts';


document.addEventListener('DOMContentLoaded', function() {
  //画像保存制御
  $('img').attr('oncontextmenu', 'return false;');
  $('img').attr('onselectstart', 'return false;');
  $('img').attr('onmousedown', 'return false;');

  //100vh
  let vh = $(window).innerHeight() * 0.01;
  $('body').get(0).style.setProperty('--vh', `${vh}px`);
  $(window).resize(() => {
    vh = $(window).innerHeight() * 0.01;
    $('body').get(0).style.setProperty('--vh', `${vh}px`);
  });
})

function App() {

  return (
    <>
      <Header />
      <main className={styles.ly_main}>
      <ScrollToTop />
      <Routes>
        {/* <Route path="/test" element={<Test />}></Route> */}
        {/*<Route path="/components" element={<Components />}></Route>*/}
        <Route index element={<Top />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/policy" element={<Policy />}></Route>
        {/*<Route path="/news" element={<ArchiveNews />}></Route>
        <Route path="/contact" element={<Contact />}></Route>*/}
        <Route path="/thanks" element={
          <Various type={VARIOUS_ID_CONTACT_COMPLETE}></Various>}>
        </Route>
        <Route path="/error" element={
          <Various type={VARIOUS_ID_CONTACT_ERROR}></Various>}>
        </Route>
        <Route path="*" element={
          <Various></Various>}/>
        <Route path="/news" element={<Pre />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
      </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
