import styles from '../../assets/sass/components/index.module.scss';
import {Link} from 'react-router-dom'
import { smoothLink } from '../../common/smoothLink';
import { HashLink } from 'react-router-hash-link';

/**
 * ボタン
 * @param string type タイプ(a:aタグ button:buttonタグ)
 * @param string link リンク(リンクの場合はroute付与)
 * @param string anchor 他画面へのアンカーリンク　該当のid
 * @param string smooth 同一画面内のスムーススクロール　該当のid
 * @param string children テキスト
 */
export const Button = (props) => {

  const {type, link=null, anchor=null, smooth=null, children} = props;

  if(type === 'a') {
    if(anchor) {
      return (
        <span className={styles.el_button} href={link}>
          <HashLink to={`${link}#${anchor}`}>
            <span className={styles.el_button_txt}>{children}</span>
          </HashLink>
        </span>
      );
    } else {
      return (
        <span className={styles.el_button} href={link}>
          <Link to={link} onClick={() => smoothLink(smooth)}>
            <span className={styles.el_button_txt}>{children}</span>
          </Link>
        </span>
      );
    }
  } else if(type === 'button') {
    return (
        <button className={styles.el_button} type='submit'>
          <span className={styles.el_button_txt}>{children}</span>
        </button>
      );
  }
}
