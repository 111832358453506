import styles from '../../assets/sass/components/index.module.scss';
import { RECEPTION_TIME, REGULAR_HOLIDAY } from '../../common/Consts';
import { Button } from '../elements/Button';
import { Tel } from '../elements/Tel';

export const Reservation = (props) => {
  const {button=null, link=null, txt=null} = props;

  return (
    <div className={styles.bl_reservation}>
      <h4 className={styles.bl_reservation_head}>ご予約はこちらから</h4>
      <p className={styles.bl_reservation_ex}>
        {`電話受付時間：${RECEPTION_TIME}　${REGULAR_HOLIDAY}`}
      </p>
      <Tel />
    </div>
  )
}
