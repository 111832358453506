import pages from '../assets/sass/pages/about.module.scss';
import helpers from '../assets/sass/components/helper.module.scss';
import '../assets/sass/components/nomodule/plugin.scss';
import { Helmet } from 'react-helmet-async';
import { NAME } from '../common/Consts';
import { Section } from '../components/layouts/Section';
import { Container } from '../components/layouts/Container';
import { ColorBg } from '../components/layouts/ColorBg';
import { ReservationButton } from '../components/blocks/RervationButton';
import { Head } from '../components/elements/Head';
import { Circle } from '../components/elements/Circle';
import { Square } from '../components/elements/Square';
import { Title } from '../components/elements/Title';
import { MarkMd } from '../components/elements/MarkMd';
import { BgReact } from '../components/blocks/BgReact';

export const About = () => {

  return (
    <>
      <Helmet>
        <title>アール・アイ整体 | イネイト活性療法とは</title>
      </Helmet>
      <Head>イネイト活性療法とは</Head>
      <div className={pages.about_ex}>
        <Container>
          <p className={pages.about_ex_txt}>
            イネイト活性療法®とは、治癒力を医療として活用し、病気・症状の原因部分に治癒力を意図的に<br className={helpers.hp_none__sp} />かつ効果的に働かせ改善に導く、治すために特化したメディカル治療法です。
          </p>
          <p className={pages.about_ex_txt}>
            機械などは一切使わず術者でのみ検査をします。脳や身体の情報・反応機能を応用して<br className={helpers.hp_none__sp} />全身の異常部位や症状の根本原因を的確に検出していきます。
          </p>
          <p className={pages.about_ex_txt}>
            そのため、その場しのぎの施術ではなく、症状が繰り返さないようにすることができる「根本改善」が可能です。
          </p>
          <p className={pages.about_ex_txt}>
            また、イネイト活性療法は、無痛整体ともいわれる療法です。その名前の通り、全く痛みはなく、無痛で行うことができます。
          </p>
          <div className={pages.about_ex_circle}>
            <Circle />
          </div>
          <div className={pages.about_ex_square}>
            <Square />
          </div>
        </Container>
      </div>
      <div className={pages.about_problem}>
        <p className={pages.about_problem_txt}>
          自分で色々ケアをしているが<br className={helpers.hp_none__pc} />一向に直らない。
        </p>
        <p className={pages.about_problem_txt}>
          様々な整体に通っても<br className={helpers.hp_none__pc} />良くならない。<br className={helpers.hp_none__pc} />すぐにぶり返す。
        </p>
        <p className={pages.about_problem_point}>
          そのような、<br className={helpers.hp_none__pc} /><span className={pages.about_problem_point_bg}>なかなか治らない<br className={helpers.hp_none__pc} />慢性的なトラブル</span><br className={helpers.hp_none__pc} />を抱えてはいませんか？<br className={helpers.hp_none__pc} />
        </p>
        <div className={pages.about_problem_imgWrap}>
          <div className={pages.about_problem_circle}>
            <Circle />
          </div>
          <img className={helpers.hp_none__pc} src={`${process.env.PUBLIC_URL}/img/img_about_problem_sp.webp`} alt="" />
          <img className={helpers.hp_none__sp} src={`${process.env.PUBLIC_URL}/img/img_about_problem_pc.webp`} alt="" />
        </div>
        <Container>
          <div className={pages.about_problem_exWrap}>
            <div className={pages.about_problem_exWrap_circle}>
              <Circle />
            </div>
            <div className={pages.about_problem_exWrap_txtWrap}>
              <p className={pages.about_problem_exWrap_txt}>
                なぜこのような慢性的なトラブルはなかなか治らないのでしょうか。<br />脳がそのトラブルに気づいていないからなのです。<br />脳がトラブルに気づくことができれば、体は改善する方へと進みます。<br />しかし、その場しのぎのケアでは、その時は症状が改善しても、根幹である脳がそのトラブルを検知しきれておらず、結果すぐに再発を起こしてしまうのです。
              </p>
              <p className={pages.about_problem_exWrap_txt}>
                イネイト活性療法ができることは、脳にトラブルを知らせることです。<br />体・生命の中枢部位である脳幹を活性化させ、トラブルがあること知らせることで、体自体の生命力を強くすると共に、トラブルを改善するように、脳が体へ指示できる環境を作り出します。
              </p>
            </div>
            <div className={pages.about_problem_exWrap_imgWrap}>
              <img src={`${process.env.PUBLIC_URL}/img/img_about_point.webp`} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <Section>
        <div className={pages.about_evidence}>
          <h3 className={pages.about_evidence_title}>
            イネイト活性療法は、<br className={helpers.hp_none__pc} />科学的に効果が証明された<br className={helpers.hp_none__pc} />メディカル治療法です。
          </h3>
          <Container>
            <div className={pages.about_evidence_bgReact}>
              <BgReact>
                <p>
                  HvO WORLD AWARD<br />世界初受賞<br /><br />豊田メソッドの<br className={helpers.hp_none__pc} />自立神経系への効果が<br />全世界初、医学的に<br className={helpers.hp_none__pc} />証明されました。
                </p>
              </BgReact>
            </div>
            <div className={pages.about_evidence_contents}>
              <img className={pages.about_evidence_img} src={`${process.env.PUBLIC_URL}/img/img_evidence.png`} alt="" />
              <p className={pages.about_evidence_ex}>
                HvO(エイチ・ヴィー・オー)とは、ハーバード大学やオックスフォード大学出身の委員会メンバーで構成されるアカデミーが、世界最高峰の英知を結集して世界各国から選りすぐられた特に品質・機能が秀でた商品サービスを認定。その栄誉と功績を称えるために設立されたものです。豊田竜大先生のメディカルテクニックの効果について調査・検証を行い、 日本先端医療医学会の医学誌に論文を掲載。その後、論文は英訳されHvO世界大賞を受賞しました。 自律神経系への手技整体の分野では、世界初受賞の快挙です。
              </p>
            </div>
          </Container>
        </div>
      </Section>
      <Section>
        <Title>
          エビデンスがない治療法と<br className={helpers.hp_none__pc} />ある治療法の違い
        </Title>
        <Container>
          <div className={pages.about_comparison}>
            <div className={pages.about_comparison_contents}>
              <div className={`${pages.about_comparison_titleWrap} ${pages.about_comparison_titleWrap__no}`}>
                <h3  className={pages.about_comparison_title}>
                  エビデンスがない治療
                </h3>
              </div>
              <div className={`${pages.about_comparison_list} ${pages.about_comparison_list__no}`}>
                <img src={`${process.env.PUBLIC_URL}/img/icon_xmark.svg`} alt="" />
                <p className={pages.about_comparison_ex}>
                  一時的な変化が起こる場合があるが、<br className={helpers.hp_none__sp} />元に戻ってしまう場合が多い・・・
                </p>
                <p className={pages.about_comparison_ex}>
                  効果が分からない・・・
                </p>
                <p className={pages.about_comparison_ex}>
                  安全性が証明されていないので<br />リスクが高い・・・
                </p>
              </div>
            </div>
            <div className={pages.about_comparison_contents}>
              <div className={`${pages.about_comparison_titleWrap} ${pages.about_comparison_titleWrap__on}`}>
                <h3  className={pages.about_comparison_title}>
                  エビデンスがある治療
                </h3>
              </div>
              <div className={`${pages.about_comparison_list} ${pages.about_comparison_list__on}`}>
                <img src={`${process.env.PUBLIC_URL}/img/icon_circle.svg`} alt="" />
                <p className={pages.about_comparison_ex}>
                  科学的根拠によって<br className={helpers.hp_none__sp} />効果が証明されている
                </p>
                <p className={pages.about_comparison_ex}>
                  変化ではなく改善が期待できる
                </p>
                <p className={pages.about_comparison_ex}>
                  安全性の高い治療である
                </p>
              </div>
            </div>
            <img className={pages.about_comparison_arrow} src={`${process.env.PUBLIC_URL}/img/icon_right.svg`} alt="" />
          </div>
        </Container>
      </Section>
      <Section>
        <Title>{NAME}の特徴</Title>
        <Container>
          <div className={pages.about_featureWrap}>
            <div className={pages.about_feature}>
              <div className={pages.about_feature_mark}>
                <MarkMd>1</MarkMd>
              </div>
              <p className={pages.about_feature_txt}>
                体全体の検査をし、症状の根本的な原因を調べることができる
              </p>
            </div>
            <div className={pages.about_feature}>
              <div className={pages.about_feature_mark}>
                <MarkMd>2</MarkMd>
              </div>
              <p className={pages.about_feature_txt}>
                検出したあらゆる問題について<br className={helpers.hp_none__pc} />施術ができる
              </p>
            </div>
            <div className={pages.about_feature}>
              <div className={pages.about_feature_mark}>
                <MarkMd>3</MarkMd>
              </div>
              <p className={pages.about_feature_txt}>
                施術時間はわずか数分
              </p>
            </div>
            <div className={pages.about_feature}>
              <div className={pages.about_feature_mark}>
                <MarkMd>4</MarkMd>
              </div>
              <p className={pages.about_feature_txt}>
                無痛で施術を受けることができる
              </p>
            </div>
            <div className={pages.about_feature}>
              <div className={pages.about_feature_mark}>
                <MarkMd>5</MarkMd>
              </div>
              <p className={pages.about_feature_txt}>
                体に一切負担のない安心・安全な<br className={helpers.hp_none__pc} />施術である
              </p>
            </div>
          </div>
        </Container>
      </Section>
      <div className={pages.about_end}>
        <div className={pages.about_end_txtWrap}>
          <p className={pages.about_end_txt}>
            長年の痛みや不調に苦しんでおられる方
          </p>
          <p className={pages.about_end_txt}>
            症状を本気で根本から改善したい方</p>
          <p className={pages.about_end_txt}>
            セルフケアや他整体でも<br className={helpers.hp_none__pc} />一向に改善されない方
          </p>
        </div>
        <div className={pages.about_end_bg}>
          <p className={pages.about_end_bgTxt}>
            そのお悩み、私たちであれば<br className={helpers.hp_none__pc} />解決へと導くことができます。<br />是非一度当院へお越しくださいませ。
          </p>
        </div>
      </div>
      <Section>
        <ColorBg>
          <ReservationButton link="/" anchor="fee">料金を見る</ReservationButton>
        </ColorBg>
      </Section>
    </>
  )
};
