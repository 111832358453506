import pages from "../assets/sass/pages/contact.module.scss";
import { NAME } from "../common/Consts";
import { Helmet } from "react-helmet-async";
import { ContainerMin } from "../components/layouts/ContainerMin";
import { Head } from "../components/elements/Head";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../components/elements/Button";

export const Contact = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const apiUrl = `${process.env.REACT_APP_BASE_PHP_URL}/apiSendMail.php`;
    axios
      .post(apiUrl, data)
      .then((ret) => {
        if (ret.data == 1) {
          window.location.href = "/thanks";
        } else {
          window.location.href = "/error";
        }
      })
      .catch(() => {
        window.location.href = "/error";
      });
  };

  return (
    <>
      <Helmet>
        <title>アール・アイ整体 | お問い合わせ</title>
      </Helmet>
      <Head>お問い合わせ</Head>
      <ContainerMin max="900">
        <div className={pages.contact_wrap}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* ご用件 */}
            <div className={pages.contact_inputWrap}>
              <div className={pages.contact_inputTitle}>ご用件</div>
              <div className={pages.contact_inputContent}>
                <input
                  name="title"
                  type="text"
                  {...register("title", {
                    required: { value: true, message: "ご用件は必須です" },
                  })}
                />
              </div>
              <div className={pages.contact_inputError}>
                {errors.title && errors.title.message}
              </div>
            </div>
            {/* お名前 */}
            <div className={pages.contact_inputWrap}>
              <div className={pages.contact_inputTitle}>お名前</div>
              <div className={pages.contact_inputContent}>
                <input
                  name="name"
                  type="text"
                  {...register("name", {
                    required: { value: true, message: "お名前は必須です" },
                  })}
                />
              </div>
              <div className={pages.contact_inputError}>
                {errors.name && errors.name.message}
              </div>
            </div>
            {/* フリガナ */}
            <div className={pages.contact_inputWrap}>
              <div className={pages.contact_inputTitle}>フリガナ</div>
              <div className={pages.contact_inputContent}>
                <input
                  name="kana"
                  type="text"
                  {...register("kana", {
                    required: { value: true, message: "フリガナは必須です" },
                  })}
                />
              </div>
              <div className={pages.contact_inputError}>
                {errors.kana && errors.kana.message}
              </div>
            </div>
            {/* メールアドレス */}
            <div className={pages.contact_inputWrap}>
              <div className={pages.contact_inputTitle}>メールアドレス</div>
              <div className={pages.contact_inputContent}>
                <input
                  name="email"
                  type="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "メールアドレスは必須です",
                    },
                  })}
                />
              </div>
              <div className={pages.contact_inputError}>
                {errors.email && errors.email.message}
              </div>
            </div>
            {/* 電話番号 */}
            <div className={pages.contact_inputWrap}>
              <div className={pages.contact_inputTitle}>電話番号</div>
              <div className={pages.contact_inputContent}>
                <input
                  name="tel"
                  type="tel"
                  {...register("tel", {
                    required: { value: true, message: "電話番号は必須です" },
                  })}
                />
              </div>
              <div className={pages.contact_inputError}>
                {errors.tel && errors.tel.message}
              </div>
            </div>
            {/* お問い合わせ内容 */}
            <div className={pages.contact_inputWrap}>
              <div className={pages.contact_inputTitle}>お問い合わせ内容</div>
              <div className={pages.contact_inputContent}>
                <textarea
                  name="detail"
                  type="detail"
                  {...register("detail", {
                    required: {
                      value: true,
                      message: "お問い合わせ内容は必須です",
                    },
                  })}
                ></textarea>
              </div>
              <div className={pages.contact_inputError}>
                {errors.detail && errors.detail.message}
              </div>
            </div>
            {/* プライバシーポリシー */}
            <div className={pages.contact_inputWrap}>
              <div className={pages.contact_chkWrap}>
                <input
                  id="check"
                  name="check"
                  type="checkbox"
                  {...register("check", {
                    required: { value: true, message: "チェックは必須です" },
                  })}
                />
                <label htmlFor="check">プライバシーポリシーに同意する</label>
              </div>
              <div className={pages.contact_inputError}>
                {errors.check && errors.check.message}
              </div>
            </div>
            <div className={pages.contact_sendButton_wrap}>
              <Button type="button">送信する</Button>
            </div>
          </form>
        </div>
      </ContainerMin>
    </>
  );
};
