import styles from '../../assets/sass/components/index.module.scss';

export const Title = (props) => {
  const {children} = props;
  return (
    <div className={styles.el_title}>
      <h2 className={styles.el_title_txt}>{children}</h2>
      <span className={styles.el_title_line}></span>
    </div>
  )
}
