import styles from '../assets/sass/components/index.module.scss';
import '../assets/sass/components/nomodule/plugin.scss';
import {Link} from 'react-router-dom'
import { ADDRESS, NAME, POST_CODE } from '../common/Consts';
import { Reception } from './blocks/Reception';
import { Tel } from './elements/Tel';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.min.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Footer = () => {

    // スライドにする場合は「Slider」コンポーネントに{...settings}
  const settings = {
  //   arrows: false,
  //   accessibility: false,
  //   dots: false,
  //   infinite: true,
  //   infinite: true,
  //   slidesToShow: 1.2,
  //   centerMode: false,
  //   autoplay: true,
  //   speed: 8000,
  //   autoplaySpeed: 0,
  //   pauseOnFocus: false,
  //   pauseOnHover: false,
  //   cssEase: "linear",
  //   pauseOnFocus: false,
  //   pauseOnHover: false,
  };

  const images = [
    `${process.env.PUBLIC_URL}/img/img_slide01.webp`,
    `${process.env.PUBLIC_URL}/img/img_slide02.webp`,
  ]

  return (
    <footer className={styles.fo_footer}>
      {
        // スライダー
        /* <div className={`${styles.fo_footer_slide} ${styles.fo_footer_slide__sp}`}>
          <Slider className={styles.fo_footer_slide_inner} {...settings}>
            {images.map((img, index) => (
              <img className={styles.fo_footer_slide_item} src={img} alt="" key={index} />
            ))}
          </Slider>
        </div> */
      }
      <div className={`${styles.fo_footer_slide}`}>
        {images.map((img, index) => (
          <img className={`${styles.fo_footer_slide_item} ${styles.fo_footer_slide_item__pc}`} src={img} alt="" key={index} />
        ))}
      </div>
      <div className={styles.fo_footer_contents}>
        <div className={styles.fo_footer_info}>
          <div className={styles.fo_footer_info_txtWrap}>
            <p className={styles.fo_footer_info_head}>{NAME}</p>
            <p className={styles.fo_footer_info_txt}>{POST_CODE}</p>
            <p className={styles.fo_footer_info_txt}>{ADDRESS}</p>
          </div>
          <div className={styles.fo_footer_info_reception}>
            <Reception />
          </div>
          <Tel />
        </div>
        <div className={styles.fo_footer_map}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3217.162577790501!2d139.3090809!3d36.2598317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601edef2226a65e7%3A0x7bf4df4f929b68f4!2z44CSMzcwLTA0MjIg576k6aas55yM5aSq55Sw5biC5a6J6aSK5a-655S677yV77yW4oiS77yW!5e0!3m2!1sja!2sjp!4v1701699061925!5m2!1sja!2sjp" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className={styles.fo_footer_nav}>
        <Link to='/about'><span className={styles.fo_footer_nav_link}>イネイト活性療法とは</span></Link>
        <Link to='/news'><span className={styles.fo_footer_nav_link}>お知らせ</span></Link>
        <Link to='/contact'><span className={styles.fo_footer_nav_link}>お問い合わせ</span></Link>
        <Link to='/policy'><span className={styles.fo_footer_nav_link}>プライバシーポリシー</span></Link>
        {/* TODO SNSリンク
        <div className={styles.fo_footer_nav_iconWrap}>
          <a href="####" target="_blank" rel="noopener noreferrer">
            <img className={styles.fo_footer_nav_icon} src={`${process.env.PUBLIC_URL}/img/icon_insta.svg`} alt="" />
          </a>
          <a href="####" target="_blank" rel="noopener noreferrer">
            <img className={styles.fo_footer_nav_icon} src={`${process.env.PUBLIC_URL}/img/icon_facebook.svg`} alt="" />
          </a>
        </div>
        TODO SNSリンク - END */}
      </div>
      <p className={styles.fo_footer_copy}>
        &copy; 2023 {NAME}
      </p>
    </footer>
  )
};
