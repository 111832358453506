import { Button } from "./components/elements/Button"

export const Pre = () => {
  return (
    <div style={{}}>
      <p style={{marginTop:'350px', textAlign:'center'}}>準備中です。しばらくお待ちください。</p>
      <div style={{marginTop:'60px', textAlign:'center'}}>
        <Button type="a" link="/">トップへ戻る</Button>
      </div>
    </div>
  )
}
