import styles from '../../assets/sass/components/index.module.scss';
import { RECEPTION_TIME } from '../../common/Consts';

export const Reception = () => {
  return (
    <div className={styles.bl_reception}>
      <div className={styles.bl_reception_inner}>
        <span className={styles.bl_reception_inner_head}>受付時間</span>
        <div className={styles.bl_reception_inner_contents}>
          <span className={styles.bl_reception_inner_content}>月</span>
          <span className={styles.bl_reception_inner_content}>火</span>
          <span className={styles.bl_reception_inner_content}>水</span>
          <span className={styles.bl_reception_inner_content}>木</span>
          <span className={styles.bl_reception_inner_content}>金</span>
          <span className={styles.bl_reception_inner_content}>土</span>
          <span className={styles.bl_reception_inner_content}>日</span>
        </div>
      </div>
      <div className={styles.bl_reception_inner}>
        <span className={styles.bl_reception_inner_head}>
          <span className={styles.bl_reception_inner_head_time}>{RECEPTION_TIME}</span>
        </span>
        <div className={styles.bl_reception_inner_contents}>
          <span className={styles.bl_reception_inner_on}></span>
          {/* 定休日 */}
          {/* <span className={styles.bl_reception_inner_off}></span> */}
          <span className={styles.bl_reception_inner_on}></span>
          <span className={styles.bl_reception_inner_on}></span>
          <span className={styles.bl_reception_inner_on}></span>
          <span className={styles.bl_reception_inner_on}></span>
          <span className={styles.bl_reception_inner_on}></span>
          <span className={styles.bl_reception_inner_on}></span>
        </div>
      </div>
      <p className={styles.bl_reception_ex}>※不定休</p>
    </div>
  )
}
