import styles from '../../assets/sass/components/index.module.scss';

export const MarkMd = (props) => {
  const {children} = props;
  return (
    <span className={styles.el_markMd}>
      <span className={styles.el_markMd_txt}>{children}</span>
    </span>
  )
}
