import styles from '../../assets/sass/components/index.module.scss';
import { TEL_NUMBER } from '../../common/Consts';

export const Tel = () => {
  return (
    <a className={styles.el_tel} href={`tel:${TEL_NUMBER}`}>
      <span className={styles.el_tel_subject}>Tel&#46;</span>
      <span className={styles.el_tel_number}>{TEL_NUMBER}</span>
    </a>
  )
}
