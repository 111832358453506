import styles from '../../assets/sass/components/index.module.scss';

export const Head = (props) => {
  const {children} = props;
  return (
    <div className={styles.el_head}>
      <h1 className={styles.el_head_txt}>{children}</h1>
    </div>
  )
}
